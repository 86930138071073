import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import AddSecurityDrawer from "./AddSecurityDrawer";
import SecurityNoti from "./SecurityNoti";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import IconButton from "@mui/material/IconButton";
import SecurityIcon from "@mui/icons-material/Security";
import { getTestOfNewDocuments } from "../function";
import { Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useDispatch } from "react-redux";
import { setSecurityData } from "../../store/slices/Userslice";
import axios from "axios";
import { socket } from "../../../socket";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: "purple",
    fontWeight: "1800",
    borderRight: "none",
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: "none",
  },
}));

const CustomHeader = (props) => {
  return <div style={{ textAlign: "center" }}>{props.label}</div>;
};

const Security = (props) => {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [showSecurityNoti, setShowSecurityNoti] = useState(false);
  const [countSec, setCountSec] = useState(null);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    socket.on('receive-security', (data) => {
      if (data?._doc?.societyName && data?._doc?.buttonType === info.SubRole) {

        const transformedData = {
          ...data?._doc,
          uid: data?._doc?.uid?._id,
          id: data?._doc?._id
        }

        console.log(data);
        const duplicateArray = [...rowData, transformedData]; 
        duplicateArray.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime))
        setRowData(duplicateArray); 
      }
    });

    return () => {
    };
  }, [rowData]); 

console.log(info.societyName,"uuuhhhhhh")

  const fetchDataApi = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/security`);
      const data = response.data.map(({ userId, ...rest }) => ({ ...rest, uid: rest?.uid?._id, id: rest?._id }));
      data.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime));

      setRowData(data);
      setTempData([]);
      console.log(rowData, "api");
    } catch (error) 
    {
      console.error("Error", error);
    }
  };
 

  const fetchCountSec = async () => {
    try {
      const count = await getTestOfNewDocuments("button-one");
      return count;
    } catch (error) {
      console.error("Error getting false documents:", error);
      return 0;
    }
  };

  const countSecPromise = fetchCountSec();

  countSecPromise.then((countData) => {
    setCountSec(countData);
  });

  useEffect(() => { }, [countSec]);

  const handleSecurityEdit = (row) => {
    setOpenEdit(true);
    dispatch(setSecurityData(row));
  };

  const handleSecurityNoti = () => {
    setShowSecurityNoti(!showSecurityNoti);
  };

  const editColumn = {
    field: "Security",
    headerName: "Edit",
    headerClassName: classes.headerCell,
    width: 120,
    renderCell: (cellData) => (
      <SecurityIcon
        style={{
          margin: "0 10px",
          cursor: "pointer",
        }}
        onClick={(e) => handleSecurityEdit(cellData.row)}
      >
        Action
      </SecurityIcon>
    ),
  };

  return (
    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{
            width: "100%",
            borderRadius: "15px",
            padding: "10px",
            background: "white",
            boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
            marginTop: "50px",
            marginLeft: "-15px",
          }}
          columns={[
            editColumn,
            {
              field: "name",
              width: 180,
              headerName: "Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "phoneNo",
              width: 180,
              headerName: "Phone No",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "address",
              width: 200,
              headerName: "Address",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "time",
              headerName: "Time",
              width: 160,
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "date",
              headerName: "Date",
              width: 160,
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fname",
              width: 180,
              headerName: "Father Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fPhoneNo",
              width: 180,
              headerName: "Father Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "fmName",
              width: 180,
              headerName: "Family Member Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fmphoneNo",
              width: 180,
              headerName: "Family Membe Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fmName1",
              width: 180,
              headerName: "Family Member Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fmphoneNo1",
              width: 180,
              headerName: "Family Membe Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
          ]}
          rows={rowData.filter((vv) => vv.edit === false && vv.societyName === info.societyName)}
          pagination={true}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "white",
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px",
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
          getRowClassName={rowClassName(classes)}
        />
      </div>
      <AddSecurityDrawer open={openEdit} close={setOpenEdit} reCall={()=>fetchDataApi()}/>
      {showSecurityNoti && (
        <SecurityNoti
          open={showSecurityNoti}
          close={() => setShowSecurityNoti(false)}
        />
      )}
      <Tooltip title={`New Requests ${countSec}`} placement="left">
        <IconButton
          aria-label={countSec}
          style={{
            position: "absolute",
            top: "25px",
            right: "45px",
            zIndex: 1100,
          }}
          onClick={() => {
            handleSecurityNoti();
          }}
        >
          <Badge badgeContent={countSec} color="secondary">
            <NotificationsNoneIcon
              style={{
                fontSize: 35,
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </AdminDashboard>
  );
};
export default Security;
