import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddViewDrawer from "./AddViewDrawer";
import { useDispatch } from "react-redux";
import { setCompletedGroceryData } from "../../store/slices/Userslice";
import axios from "axios";


const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple',
    fontWeight: '1800',
    borderRight: 'none',
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: 'none',
  },
}));



const CompletedGrocery = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openView, setOpenView] = useState(false);
  const [rowData, setRowData] = useState([]);
  const info = JSON.parse(localStorage.getItem("currentUser"));


  useEffect(() => {
fetchDataApi()
  }, []);

  const fetchDataApi = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/orders/`)
    console.log(response.data)
    const data = response.data.map(({ userId, ...rest }) => ({ ...rest, ...userId, uid: userId?._id, id: rest?._id }))
    console.log('data', data)
    data.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime));

    setRowData(data)
    console.log(rowData, "ooo")
  }

  const handleResponseView = (row) => {
    setOpenView(true);
    dispatch(setCompletedGroceryData(row))
  };



  const viewColumn = {
    field: "View",
    width: 120,
    renderCell: (cellData) => (
      <RemoveRedEyeIcon
        style={{ margin: "0 10px", cursor: "pointer" }}
        onClick={(e) => handleResponseView(cellData.row)}
      >
        Action
      </RemoveRedEyeIcon>
    ),
  };

  return (
    <>
      <AdminDashboard>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            className={classes.roots}
            style={{ width: "100%", borderRadius: "15px", padding: "10px", background: "white", boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)", marginTop: "50px", marginLeft: "-15px" }}
            columns={[
              viewColumn,
              { field: "customerName", headerName: "Name", width: 200 },
              { field: "customerPhone", headerName: "Phone No", width: 200 },
              { field: "customerAddress", headerName: "Address", width: 200 },
              { field: "customerEmail", headerName: "Email", width: 200 },
              { field: "Fname", headerName: "Father Name", width: 200 },
              { field: "Fphone", headerName: "Father Phone#", width: 200 },
              { field: "FMname", headerName: "Family Member Name", width: 200 },
              { field: "FMphone", headerName: "Family Member Phone#", width: 200 },
              { field: "FMname1", headerName: "Family Member Name", width: 200 },
              { field: "FMphone1", headerName: "Family Member Phone", width: 200 },

            ]}
            rows={rowData.filter(
              (val) => val.update === true && val.societyName === info.societyName
            )}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    backgroundColor: 'white',
                    border: "black",
                    boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",// Set your desired background color here
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: 'center',
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "600px",
                    marginLeft: "150px",
                    height: "35px",
                    marginTop: "-60px"
                  }}
                >
                  <GridToolbar {...props} />
                </div>
              ),

            }}
            getRowClassName={rowClassName(classes)}

          />
        </div>

        <AddViewDrawer
          open={openView}
          close={() => setOpenView(false)}
        />

      </AdminDashboard>
    </>
  );
};

export default CompletedGrocery;
