import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { configapp } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from '@mui/material/IconButton';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import { Tooltip } from "@mui/material";
import AddNotHomeDrawer from "./AddNotHomeDrawer";
import AddNotHomeView from "./AddNotHomeView";
import NotHomeNoti from "./NotHomeNoti";
import Badge from "@mui/material/Badge";
import { useDispatch } from "react-redux";
import { setNHData } from "../../store/slices/Userslice";
import axios from "axios";
import { socket } from "../../../socket";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple',
    fontWeight: '1800',
    borderRight: 'none',
    fontSize: "16px",
    textAlign: "center",
   
  },
  rowCell: {
    borderBottom: 'none',
  },
}));

const CustomHeader = (props) => {
  return <div style={{ textAlign: 'center' }}>{props.label}</div>;
};



const NotHome = (props) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
   const [rowData, setRowData] = useState([]);
 const [showNotHomeNoti, setShowNotHomeNoti] = useState(false);
  const [countNH, setCountNH] = useState(null);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    fetchDataApi()
  }, []);

  useEffect(() => {
    socket.on('receive-not-at-home', (data) => {
      if (data?._doc?.societyName && data?._doc?.buttonType === info.SubRole) {

        const transformedData = {
          ...data?._doc,
          uid: data?._doc?.uid?._id,
  id: data?._doc?._id
        }

        console.log(data);
        const duplicateArray = [...rowData, transformedData];
        duplicateArray.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime))
        setRowData(duplicateArray);
      }
    });

    return () => {
    };
  }, [rowData]);

  const fetchDataApi = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/nothome`);
      const data = response.data.map(({ userId, ...rest }) => ({ ...rest, uid: rest?.uid?._id, id: rest?._id }));
      data.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime));

      setRowData(data);
      setTempData([]);
      console.log(rowData, "api");
    } catch (error) {
      console.error("Error", error);
    }
  };


  const handleNotHomeEdit = (row) => {
setOpenEdit(true);
dispatch(setNHData(row));
  };

  const handleNotHomeView = (row) => {
  setOpenView(true);
  dispatch(setNHData(row));
  };

  const handleNotHomeNoti = () => {
    setShowNotHomeNoti(!showNotHomeNoti);
  };

 
  const editColumn = {
    field: "NotHome",
    headerName: "Action",
    headerClassName: classes.headerCell,
    width: 130,
    renderCell: (cellData) => (
      <SafetyCheckIcon
        style={{
          margin: "0 10px",
          cursor: "pointer",
        }}
        onClick={(e) => handleNotHomeEdit(cellData.row)}
      >
        Action
      </SafetyCheckIcon>
    ),
  };

  const viewColumn = {
    field: "View",
    headerName: "View",
    headerClassName: classes.headerCell,
    width: 130,
    renderCell: (cellData) => (
      <RemoveRedEyeIcon
        style={{
          margin: "0 10px",
          cursor: "pointer",
        }}
        onClick={(e) => handleNotHomeView(cellData.row)}
      >
        Action
      </RemoveRedEyeIcon>
    ),
  };

  

  return (
    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{ width: "100%", borderRadius: "15px", padding: "10px", background: "white", boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)", marginTop: "50px", marginLeft: "-15px" }}
          columns={[
            editColumn,
            viewColumn,
            { field: "Name", width: 180, headerName: "Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "PhoneNo", width: 180, headerName: "Phone No", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "from", width: 150, headerName: "From", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "to", width: 150, headerName: "To", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "Address", width: 200, headerName: "Address", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "Email", width: 250, headerName: "Email Address", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fname", width: 250, headerName: "Father Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fPhoneNo", width: 250, headerName: "Father Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmName", width: 250, headerName: "Family Member Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmphoneNo", width: 250, headerName: "Family Member Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmName1", width: 250, headerName: "Family Member Name", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },
            { field: "fmphoneNo1", width: 250, headerName: "Family Member Phone#", headerClassName: classes.headerCell, headerRender: (params) => <CustomHeader {...params} /> },

          ]}
          rows={rowData.filter((vv) => vv.Status === true && vv.societyName === info.societyName)}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: 'white',
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: 'center',
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px"
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
          getRowClassName={rowClassName(classes)}
        />
      </div>
      <AddNotHomeDrawer
        open={openEdit}
        close={setOpenEdit}
        reCall={() => fetchDataApi()}

        />
      <AddNotHomeView
        open={openView}
        close={setOpenView}/>

      {showNotHomeNoti && (
        <NotHomeNoti
          open={showNotHomeNoti}
          close={() => setShowNotHomeNoti(false)}
           />
      )}
      <Tooltip title={`New Requests ${countNH}`} placement="left">
        <IconButton
          aria-label={countNH}
          style={{
            position: "absolute",
            top: "25px",
            right: "45px",
            zIndex: 1100,
          }}
          onClick={() => {
            handleNotHomeNoti();
          }}
        >
          <Badge badgeContent={countNH} color="secondary">
            <NotificationsNoneIcon
              style={{
                fontSize: 35,
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </AdminDashboard>
  );
};

export default NotHome;
