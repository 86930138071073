
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import EditSharpIcon from '@mui/icons-material/EditSharp';
import AddGroceryDrawerEdit from "../Grocery/AddGroceryDrawerEdit";
import IconButton from '@mui/material/IconButton';
import GroceryNoti from "./AddGroceryNoti";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Tooltip } from "@mui/material";
import { getTestOfNewDocuments } from "../function";
import Badge from '@mui/material/Badge';
import { useDispatch } from "react-redux";
import { setGroceryData } from "../../store/slices/Userslice";
import { configapp } from "../../../firebase";
import axios from "axios";
import { socket } from "../../../socket";


const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: 'purple',
    fontWeight: '1800',
    borderRight: 'none',
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: 'none',
  },
}));


const Grocery = (props) => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [showGroceryNoti, setShowGroceryNoti] = useState(false);
  const [countGro, setCountGro] = useState(null);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    fetchDataApi();
  }, []);

  useEffect(() => {
    socket.on('receive-security', (data) => {
      if (data?._doc?.societyName && data?._doc?.buttonType === info.SubRole) {

        const transformedData = {
          ...data?._doc,
          uid: data?._doc?.uid?._id,
          id: data?._doc?._id
        }

        console.log(data);
        const duplicateArray = [...rowData, transformedData];
        duplicateArray.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime))
        setRowData(duplicateArray);
      }
    });

    return () => {
    };
  }, [rowData]);



  const fetchDataApi = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/grocery`);
      const data = response.data.map(({ userId, ...rest }) => ({ ...rest, uid: rest?.uid?._id, id: rest?._id }));
      data.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime));

      setRowData(data);
      setTempData([]);
      console.log(rowData, "api");
    } catch (error) {
      console.error("Error", error);
    }
  };



  // const fetchCountGro = async () => {
  //   try {
  //     const count = await getTestOfNewDocuments("button-three");
  //     return count;
  //   } catch (error) {
  //     console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countGroPromise = fetchCountGro(); 

  // countGroPromise.then((countData) => {
  //   setCountGro(countData)
  // });


  // useEffect(() => {
  // }, [

  //   countGro,
  // ]);


  const handleGroceryEdit = (row) => {
    setOpenEdit(true);
    dispatch(setGroceryData(row));
  };

  const handleGroceryNoti = () => {
    setShowGroceryNoti(!showGroceryNoti);
  };

  const editColumn = {
    field: "Action",
    width: 120,
    renderCell: (cellData) => (
      <EditSharpIcon
        style={{
          margin: "0 10px",
          cursor: "pointer",
        }}
        onClick={(e) =>
          handleGroceryEdit(
            cellData.row
          )
        }
      >
        Action
      </EditSharpIcon>

    ),
  };

  return (
    <>
      <AdminDashboard>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            className={classes.roots}
            style={{
              width: "100%",
              borderRadius: "15px",
              padding: "10px",
              background: "white",
              boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
              marginTop: "50px",
              marginLeft: "-15px",
            }}
            columns={[
              editColumn,
              { field: "name", headerName: "Name", width: 180 },

              { field: "phoneNo", headerName: "Phone No", width: 180 },
              { field: "address", headerName: "Address", width: 200 },
              { field: "age", width: 100 },
              { field: "email", headerName: "Email Address", width: 200 },
              { field: "fname", width: 250, headerName: "Father name" },

              {
                field: "fPhoneNo",
                width: 250,
                headerName: "Father Phone number",
              },
              {
                field: "fmName",
                headerName: "Family Member Name",
                width: 250,
              },
              {
                field: "fmphoneNo",
                headerName: "Family Member Phone#",
                width: 250,
              },
              {
                field: "fmName1",
                headerName: "Family Member Name",
                width: 250,
              },
              {
                field: "fmphoneNo1",
                headerName: "Family Member Phone#",
                width: 250,
              },
            ]}
            rows={rowData.filter((val) => val.edit === false && val.societyName === info.societyName)}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    backgroundColor: "white",
                    border: "black",
                    boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "600px",
                    marginLeft: "150px",
                    height: "35px",
                    marginTop: "-60px",
                  }}
                >
                  <GridToolbar {...props} />
                </div>
              ),
            }}
            getRowClassName={rowClassName(classes)}
          />
        </div>

        <AddGroceryDrawerEdit
          open={openEdit}
          close={() => setOpenEdit(false)}
          reCall={()=>fetchDataApi()}

        />
        {showGroceryNoti && (
          <GroceryNoti
            open={showGroceryNoti}
            close={() => setShowGroceryNoti(false)}
          />
        )}
        <Tooltip title={`New Requests ${countGro}`} placement="left">
          <IconButton
            ariel-label={countGro}
            style={{
              position: "absolute",
              top: "25px",
              right: "45px",
              zIndex: 1100,
            }}
            onClick={() => {
              handleGroceryNoti();
            }}
          >
            <Badge badgeContent={countGro} color="secondary">
              <NotificationsNoneIcon
                style={{
                  fontSize: 35,
                }}
              />
            </Badge>
          </IconButton>
        </Tooltip>
      </AdminDashboard>
    </>
  );
};
export default Grocery;

