import React, { useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { configapp } from "../../firebase";
import "firebase/firestore";
import axios from "axios";

const LoginForm = (props) => {
  const [showPassword, setShowPassword] = useState(false);



  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Provide a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const { email, password } = values;

   
    },
  });

  // Destructure formik values, errors, and event handlers
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  // Function to show error notification using react-toastify
  const notify = (error) =>
    toast(error, { position: "top-left", type: "error" });

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const postData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/society/login`,
        {
          email: values.email,
          password: values.password,
        }
      );
      console.log(response.data);
      if (response.data.Role === "Admin") {
        const currentUserData = JSON.stringify(response.data);
        localStorage.setItem("currentUser", currentUserData);
        props.history.push("/");
        window.location.reload();
      } else {
        toast.error("Invalid email or password. Please try again.", { position: "top-left" });
      }

    } catch (error) {
      if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
        notify("Invalid email or password. Please try again.");
      }
      else {
        // For other Firebase authentication errors, show a generic message
        toast.error("Invalid email or password. Please try again.", { position: "top-left" });
      }
    }
  };


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            initial={{ opacity: 0, y: 40 }}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              name="email"
              placeholder="Email Address"
              value={values.email}
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={values.password}
              name="password"
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box initial={{ opacity: 0, y: 20 }} sx={{ mt: "20px" }}>
            <Button fullWidth size="large" variant="contained" color="primary" type="submit" onClick={() => {
              postData();
            }}>
              Login
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
