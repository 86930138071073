import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { configapp } from "../../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import AdminDashboard from "../Dashboard";
import { getTestOfNewDocuments } from "../function";
import axios from "axios";

const rowClassName = (classes) => (params) => {
  return classes.rowCell;
};

const useStyles = makeStyles((theme) => ({
  headerCell: {
    color: "purple",
    fontWeight: "1800",
    borderRight: "none",
    fontSize: "16px",
    textAlign: "center",
  },
  rowCell: {
    borderBottom: "none",
  },
}));

const CustomHeader = (props) => {
  return <div style={{ textAlign: "center" }}>{props.label}</div>;
};

const NotHomeCancelled = (props) => {
  const classes = useStyles();
  const [countNH, setCountNH] = useState(null);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    fetchDataApi()
  }, []);

  const fetchDataApi = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/nothome/`)
    const data = response.data.map(({ userId, ...rest }) => ({ ...rest, uid: rest?.uid?._id, id: rest?._id }))
    console.log(response.data)
    data.sort((a, b) => new Date(b.pressedTime) - new Date(a.pressedTime));

    console.log(data, "data")
    setRowData(data)

  }

  const fetchCountNH = async () => {
    try {
      const count = await getTestOfNewDocuments("not_Home");
      return count;
    } catch (error) {
      console.error("Error getting false documents:", error);
      return 0;
    }
  };

  const countNHPromise = fetchCountNH();

  countNHPromise.then((countData) => {
    setCountNH(countData);
  });

  useEffect(() => {}, [countNH]);


  return (
    <AdminDashboard>
      <div style={{ width: "100%" }}>
        <DataGrid
          autoHeight
          className={classes.roots}
          style={{
            width: "100%",
            borderRadius: "15px",
            padding: "10px",
            background: "white",
            boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
            marginTop: "50px",
            marginLeft: "-15px",
          }}
          columns={[
            {
              field: "Name",
              width: 180,
              headerName: "Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "PhoneNo",
              width: 180,
              headerName: "Phone No",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "from",
              width: 150,
              headerName: "From",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "to",
              width: 150,
              headerName: "To",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "Address",
              width: 200,
              headerName: "Address",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "Email",
              width: 250,
              headerName: "Email Address",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "fname",
              width: 250,
              headerName: "Father Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fPhoneNo",
              width: 250,
              headerName: "Father Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "fmName",
              width: 250,
              headerName: "Family Member Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fmphoneNo",
              width: 250,
              headerName: "Family Member Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },

            {
              field: "fmName1",
              width: 250,
              headerName: "Family Member Name",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
            {
              field: "fmphoneNo1",
              width: 250,
              headerName: "Family Member Phone#",
              headerClassName: classes.headerCell,
              headerRender: (params) => <CustomHeader {...params} />,
            },
          ]}
          rows={rowData.filter((vv)=>vv.cancelled === true && vv.societyName === info.societyName)}
          pagination
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{
            Toolbar: (props) => (
              <div
                style={{
                  backgroundColor: "white",
                  border: "black",
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.2)",
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  alignContent: "center",
                  alignSelf: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "600px",
                  marginLeft: "150px",
                  height: "35px",
                  marginTop: "-60px",
                }}
              >
                <GridToolbar {...props} />
              </div>
            ),
          }}
          getRowClassName={rowClassName(classes)}
        />
      </div>
    </AdminDashboard>
  );
};
export default NotHomeCancelled;
