import { configapp, stis } from "../../../firebase";
import AdminDashboard from "../Dashboard";
// import Icon from '@mui/material/Icon';
import React, { useRef, useState } from "react";
import { TextField, Box, CircularProgress } from "@mui/material";
import { Stack } from "react-bootstrap";
import { SolidButton } from "../../Reuse/SolidButton";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import { Typography } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const Announcements = (props) => {
  const mediaInputRef = useRef(null);
  const [fileUrl, setFileUrl] = useState([]);
  const [mediaPath, setMediaPath] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [mediaSequence, setMediaSequence] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [showPost, setShowPost] = useState(true);
  const [showCms, setShowCms] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));

  const listStyle = {
    display: "inline-block",
    marginRight: "40px",
    marginLeft: "-40px",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      setActiveSlide(index);
    },
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  };

  const handleMediaInputChange = async () => {
    const files = mediaInputRef.current.files;

    if (files && files.length > 0) {
      if (files.length > 1) {
        notifyError("You can only upload one media file at a time.");
        return;
      }

      const selectedMedia = [...files];
      const mediaNames = selectedMedia.map((file) => file.name);
      const newFileUrls = [...fileUrl];

      setFileUrl([...fileUrl, ...mediaNames]);

      const mediaType = selectedMedia[0].type.startsWith("image")
        ? "image"
        : "video";
      const filesUrls = selectedMedia.map((file) => URL.createObjectURL(file));

      newFileUrls.push(...filesUrls);

      setMediaSequence((prevSequence) => [
        ...prevSequence,
        ...mediaNames.map((name) => ({ type: mediaType, path: name })),
      ]);
      setFileUrl(newFileUrls);
    }
  };

  const handleRemoveMedia = (index) => {
    const updatedMedia = [...fileUrl];
    updatedMedia.splice(index, 1);
    setFileUrl(updatedMedia);

    const updatedSequence = [...mediaSequence];
    updatedSequence.splice(index, 1);
    setMediaSequence(updatedSequence);
    setMediaPath("");
  };

  const handleUploadClick = async () => {
    if (
      (youtubeLink !== "" && fileUrl.length > 0) ||
      (youtubeLink === "" && fileUrl.length === 0)
    ) {
      notifyError("Please ");
      return;
    }

    if (fileUrl.length > 1) {
      notifyError(
        "Please check the number of uploaded media files or fill in the description."
      );
      return;
    }

    setIsUploading(true);

    try {
      let mediaUrls = [];

      // Upload media files
      if (fileUrl.length > 0) {
        mediaUrls = await Promise.all(
          fileUrl.map(async (mediaName) => {
            const mediaUrl = await uploadFile(
              mediaInputRef.current.files[fileUrl.indexOf(mediaName)],
              "media"
            );

            console.log("Uploaded image URL:", mediaUrl); // Console log the uploaded image URL

            return mediaUrl;
          })
        );
      }

      await announcementApi(mediaUrls[0] || ""); // Pass the first media URL to the API

      setDescription("");
      setIsUploading(false);
      // window.location.reload();
    } catch (error) {
      notifyError("Error uploading files or saving data to Firestore.");
      console.error(error);
      setIsUploading(false);
    }
  };

  const uploadFile = async (file, fileType) => {
    const storageRef = ref(stis, `${fileType}/${file.name}`);
    await uploadBytes(storageRef, file);

    const downloadUrl = await getDownloadURL(storageRef);

    return downloadUrl;
  };

  const announcementApi = async (imgUrl) => {
    const responseData = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/announcement/`, {
      title: title,
      imgUrl: imgUrl,
      description: description,
      pressedTime: new Date(),
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
      seenUids: [],
      societyName: info.societyName,
    });
    console.log(responseData);
  };

  const sendPushNotification = async (userToken, documentId) => {
    try {
      const message = {
        notification: {
          title: "INVOSEG",
          body: "INVOSEG added new announcements",
        },
        data: {
          documentId: documentId,
        },
        to: userToken,
      };

      const response = await fetch("https://fcm.googleapis.com/fcm/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "key=AAAASUjPv3I:APA91bHX03eAG4W4KqHAQMbUqcIWjCyMBttDxYKeo193EC5CJobtJiOyuiFXuoOFNEbUc-d9osqFCxPz14HiimjuT_hPTu6OwRm-007mPWcWHj8SlXMkk5UjPk4C8xP-66QhoFqmOrCI",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        console.log("Push notification sent successfully", message);
      } else {
        console.error("Error sending push notification.");
      }
    } catch (error) {
      console.error("Error sending push notification:", error);
    }
  };

  const db = configapp.firestore();

  const fetchAllAndSendNotifications = async () => {
    try {
      const collection = db.collection("UserRequest");

      collection.onSnapshot((snapshot) => {
        if (snapshot.size === 0) {
          notifyError();
        } else {
          snapshot.forEach((doc) => {
            const userToken = doc.data().FCMtoken;
            if (userToken) {
              sendPushNotification(userToken);
              console.log(userToken, "kkkk");
            }
          });
        }
      });
    } catch (error) {
      console.error(
        "Error fetching FCM tokens and sending push notifications:",
        error
      );
    }
  };

  return (
    <AdminDashboard>
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "465px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "170px",
              marginTop: "5px",
              flex: 1,
              paddingRight: "20px",
            }}
          >
            <Box>
              <Stack>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Title Here"
                  maxRows={2}
                  multiline
                  style={{ width: "300px", marginBottom: "10px" }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Enter your Description"
                  maxRows={3}
                  multiline
                  style={{ width: "300px", marginBottom: "10px" }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <label style={{ width: "25%" }}>
                  <input
                    ref={mediaInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleMediaInputChange}
                    multiple
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <TextField
                      variant="outlined"
                      placeholder="Upload Media"
                      style={{ width: "180px", marginBottom: "10px" }}
                      onClick={() => mediaInputRef.current.click()}
                      value={mediaPath}
                      disabled
                    />
                    <div>
                      <label>
                        <WebAssetIcon
                          label="Browse Media"
                          fontSize="large"
                          cursor="pointer"
                          onClick={() => mediaInputRef.current.click()}
                        />
                      </label>
                    </div>
                  </div>
                </label>
              </Stack>
            </Box>
            <Box>
              {isUploading ? (
                <CircularProgress />
              ) : (
                <SolidButton
                  label="Upload"
                  fontSize="large"
                  cursor="pointer"
                  onClick={() => {
                    handleUploadClick();
                    fetchAllAndSendNotifications();
                  }}
                  style={{ width: "120px" }}
                />
              )}
            </Box>
          </div>

          <ToastContainer />
        </div>

        <div
          style={{
            height: "9rem",
            width: "44rem",
            marginTop: "-220px",
          }}
        >
          <Box style={{ marginTop: "40px" }}>
            <Typography style={{ marginTop: "20px" }}>
              <strong>Media Preview:</strong>
            </Typography>
            <ul style={{ marginTop: "10px" }}>
              {fileUrl.map((filesUrl, index) => (
                <li key={index} style={listStyle}>
                  {mediaSequence[index].type === "image" && (
                    <img src={filesUrl} height="100px" width="100px" />
                  )}
                  {mediaSequence[index].type === "video" && (
                    <video
                      src={filesUrl}
                      height="150px"
                      width="150px"
                      muted
                      autoPlay
                    />
                  )}
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemoveMedia(index)}
                  />
                </li>
              ))}
            </ul>
          </Box>
        </div>
      </>
      <div></div>

      <div style={{ marginTop: "160px", marginLeft: "25px" }}></div>
    </AdminDashboard>
  );
};

export default Announcements;
