import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { Modal } from "react-bootstrap";
import Badge from "@material-ui/core/Badge";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Browser as r } from "react-router-dom";
import mp from "./bababa.mp3";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import mainListItemsClose from "./listItemClose";
import { configapp } from "./../../firebase";
import ListItem from "@material-ui/core/ListItem";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css"; // Import the default styles for react-toastify
import "./YourToastStyles.css"; // Import your custom CSS for toast styles
import clipboardIcon from "@iconify-icons/system-uicons/clipboard";
import pendingActionsIcon from "@iconify-icons/ic/outline-pending-actions";
import clipboardTickOutlineIcon from "@iconify-icons/teenyicons/clipboard-tick-outline";
import logoutOutlineIcon from "@iconify-icons/solar/logout-2-outline";
import { getDoc, doc } from "firebase/firestore";
import { socket } from "../../socket";
import { TryOutlined } from "@mui/icons-material";
import axios from "axios";

// import { ValidationSchemaExample as Device } from "./Device";

const userRoleData = localStorage.getItem("currentUser");
const currentUser = userRoleData ? JSON.parse(userRoleData).SubRole : null;

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "rgba(255, 255, 255, 0.45)",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "rgba(255, 255, 255, 0.45)",
    color: "#000",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px", // Adjust padding for small screens
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.up("md")]: {
      display: "none", // Hide the menu button for larger screens
    },
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    display: "flex",
    alignItems: "left",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-20px",
      fontSize: "10px",
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    borderBottomRightRadius: "20px",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    boxShadow: "none",
    border: "none",
    background: "rgba(255, 255, 255, 0.45)",
  },
  playButton: {
    background: "linear-gradient(to right, #0D8AFC, #33F0B0)",
    color: "#fff",
    margin: "0 auto",
    marginTop: "20px",
    padding: "10px 20px",
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    transition: "background 0.3s ease",
    marginTop: "-70px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      display: "block",
      padding: "5px 10px",
      fontSize: "8px",
    },
    "&:hover": {
      background: "linear-gradient(to right, #005dbb, #0074e4)",
    },
  },
  empDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  listWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px 0",
    "& h6": {
      fontSize: "14px",
    },
  },
}));

const fetchLogoId = async () => {
  try {
    const logoDocRef = doc(
      configapp.firestore(),
      "Logo",
      "JBaNxlyYfAseFOcmcw04"
    ); // Replace "your_document_id" with the actual ID of your document in the Logo collection
    const logoDocSnapshot = await getDoc(logoDocRef);
    if (logoDocSnapshot.exists()) {
      return logoDocSnapshot.data().logoId;
    } else {
      console.error("Logo document not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching logoId:", error);
    return null;
  }
};

export default function AdminDashboard({ children }) {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [logoId, setLogoId] = useState("");

  const [audio] = useState(new Audio(mp));
  const [playing, setPlaying] = useState(false);
  const info = JSON.parse(localStorage.getItem("currentUser"));
  const btn = React.useRef(null);
  const [ModalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const appbarRef = useRef(null);

  useEffect(() => {
    // Trigger the automatic click event when the window is loaded
    handleAutoClick();
  }, []);

  const handleAutoClick = () => {
    // Replace 'myElement' with the actual ID of the element you want to click
    const elementToClick = appbarRef.current;

    // Check if the element exists before triggering the click event
    if (elementToClick) {
      elementToClick.click();
      console.log("Appbar clicked!");
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchLogo = async () => {
      try {
        const fetchedLogoId = await fetchLogoId();

        if (isMounted) {
          setLogoId(fetchedLogoId);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const handleInitialAudioPlay = () => {
      if (appbarRef.current) {
        appbarRef.current.click();
      }
    };
    document?.addEventListener("click", handleInitialAudioPlay);

    return () => {
      document.removeEventListener("click", handleInitialAudioPlay);
    };
  }, []);

  const notify = (msg, btns) =>
    toast(msg, {
      theme: "dark",
      position: "top-center",
      className: "custom-toast",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // onClose: ({ uid }) => btns.click()
      onClose: ({ uid }) => toggled(),
    });

  const toggle = () => {
    if (!playing) {
      setPlaying(!playing);
      audio.play();
      audio.loop = true;
    }
    // else if(!playing){
    //   setPlaying(playing);
    //   audio.pause();
    //   audio.loop = false;
    //   }
  };
  const toggled = () => {
    if (!playing) {
      setPlaying(playing);
      audio.pause();
      audio.loop = false;
    }
    //  else if(playing){
    //   setPlaying(!playing);
    //   audio.pause();
    //   audio.loop = false;
    //   }
  };

  // const updateApi = async()=>{
  //   const data = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/alert/${data?._doc},{
      
  //     }`)
  // }

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  useEffect(() => {
    socket.on('receive-security', (data) => {
      console.log('Received data:', data);  
      console.log(info.societyName, "uuuu")
      console.log(data?._doc?.societyName, "vvv")
      let securityData = [];
      console.log(data?._doc, "before iff")
      if (data?._doc && (data?._doc?.societyName == info.societyName && data?._doc?.buttonType === info.SubRole)) {
        console.log('Data inside if:', data?._doc);  
        securityData = securityData.concat(data);  
        // btn?.current?.click();
        audio.play();
        audio.loop = true;
        notify(
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ flex: 1, paddingLeft: "10px" }}>
              <h3>User Details :</h3>
              <h6>Name: {data?._doc["name"]}</h6>
              <h6>Age: {data?._doc["age"]}</h6>
              <h6>PhoneNo: {data?._doc["phoneNo"]}</h6>
              <h6>Address: {data?._doc["address"]}</h6>
              <div>
                <h3>Family Details :</h3>
                <h6>Name: {data?._doc["fmName"]}</h6>
                <h6>Name: {data?._doc["fmName1"]}</h6>
              </div>
            </div>
            &nbsp;
            <div style={{ flex: 1 }}>
              <h3>&nbsp;</h3>
              <h6>Email: {data?._doc["email"]}</h6>
              <h6>Designation: {data?._doc["designation"]}</h6>
              <h6>Owner: {data?._doc["owner"]}</h6>
              &nbsp;
              <div>
                <br />
                <br />
                <br />
                <h6>Phone: {data?._doc["fmphoneNo"]}</h6>
                <h6>Phone: {data?._doc["fmphoneNo1"]}</h6>
              </div>
            </div>
          </div>,
          btn.current
        );
      } else {
        console.log('Condition not met:', data?._doc);  // Log data when condition is not met
      }
      console.log('Security Data:', securityData);
      console.log('Document Data:', data?._doc);
    });
  }, []);


  // React.useEffect(() => {
  //   let a = 2;
  //   const unsubscribe = configapp
  //     .firestore()
  //     .collection(info.SubRole)
  //     // .limit(30)

  //     .onSnapshot((snapshot) => {
  //       snapshot.docChanges().forEach((change) => {
  //         var data = change.doc.data();
  //         const societyName = data.societyName
  //         if (change.type === "added" && societyName === info.societyName) {
  //           if (a === 3) {
  //             if (!playing) {
  //               btn.current.click();
  //             }
  //             var data = change.doc.data();
  //             // console.log(data,"uyiuytuyyu")

  //             notify(
  //               <div
  //                 style={{ display: "flex", justifyContent: "space-between" }}
  //               >
  //                 <div style={{ flex: 1, paddingLeft: "10px" }}>
  //                   <h3>User Details :</h3>
  //                   <h6>Name: {data["name"]}</h6>
  //                   <h6>Age: {data["age"]}</h6>
  //                   <h6>PhoneNo: {data["phoneNo"]}</h6>
  //                   <h6>Address: {data["address"]}</h6>
  //                   <div>
  //                     <h3>Family Details :</h3>
  //                     <h6>Name: {data["fmName"]}</h6>
  //                     <h6>Name: {data["fmName1"]}</h6>
  //                   </div>
  //                 </div>
  //                 &nbsp;
  //                 <div style={{ flex: 1 }}>
  //                   <h3>&nbsp;</h3>
  //                   <h6>Email: {data["email"]}</h6>
  //                   <h6>Designation:{data["designation"]}</h6>
  //                   <h6>Owner: {data["owner"]}</h6>
  //                   &nbsp;
  //                   <div>
  //                     <br />
  //                     <br />
  //                     <br />
  //                     <h6>Phone: {data["fmphoneNo"]}</h6>
  //                     <h6>Phone: {data["fmphoneNo1"]}</h6>
  //                   </div>
  //                 </div>
  //               </div>,
  //               btn.current
  //             );

  //             a++;
  //           }
  //         }
  //       });

  //       a = 3;
  //     });

  //   // const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/security`)
  //   // console.log(response.data)

  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);

  // React.useEffect(() => {
  //   playing ? audio.play() : audio.pause();
  //   audio.currentTime = 0;

  // }, [playing]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const [falseDocumentsCountDoc, setFalseDocumentsCountDoc] = useState("");
  // const [falseDocumentsCountDocNot, setFalseDocumentsCountDocNot] =
  //   useState("");

  // const [falseDocumentsCountGro, setFalseDocumentsCountGro] = useState("");
  // const [falseDocumentsCountSec, setFalseDocumentsCountSec] = useState("");
  // const [falseDocumentsCountComp, setFalseDocumentsCountComp] = useState("");
  // const [falseDocumentsCountNotHome, setFalseDocumentsCountNotHome] =
  //   useState("");
  // const [newDocumentsCountNotHome, setNewDocumentsCountNotHome] = useState("");

  // useEffect(() => {
  //   getCountOfNewDocuments("not_Home", setNewDocumentsCountNotHome);
  // }, [newDocumentsCountNotHome]);

  // const handleOldDocuments = async (collectionName) => {
  //   oldDocuments(collectionName);
  //   if (collectionName === "not_Home") {
  //     setNewDocumentsCountNotHome("");
  //   }
  // };

  // const handleTrueDocs = (collectionName) => {
  //   trueDocuments(collectionName);
  //   if (collectionName === "grocery") {
  //     setFalseDocumentsCountGro("");
  //   } else if (collectionName === "consultation") {
  //     setFalseDocumentsCountDoc("");
  //   } else if (collectionName === "button-one") {
  //     setFalseDocumentsCountSec("");
  //   } else if (collectionName === "not_Home") {
  //     setFalseDocumentsCountNotHome("");
  //   } else {
  //     setFalseDocumentsCountComp("");
  //   }

  //   // Add the modal data to the queue
  //   <ViewModal
  //     open={openModal}
  //     setOpen={setOpenModal}
  //     data={ModalData || []}
  //   ></ViewModal>;
  // };

  // const [countSec, setCountSec] = useState(null);
  // const [countGro, setCountGro] = useState(null);
  // const [countDoc, setCountDoc] = useState(null);
  // const [countComp, setCountComp] = useState(null);
  // const [countNH, setCountNH] = useState(null);

  // const fetchCountSec = async () => {
  //   try {
  //     const count = await getTestOfFalseDocuments("button-one");
  //     return count;
  //   } catch (error) {
  //     // console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countSecPromise = fetchCountSec();

  // countSecPromise.then((countData) => {
  //   setCountSec(countData);
  // });

  // const fetchCountDoc = async () => {
  //   try {
  //     const count = await getTestOfFalseDocuments("consultation");
  //     return count;
  //   } catch (error) {
  //     // console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countDocPromise = fetchCountDoc();

  // countDocPromise.then((countData) => {
  //   setCountDoc(countData);
  // });

  // const fetchCountGro = async () => {
  //   try {
  //     const count = await getTestOfFalseDocuments("grocery");
  //     return count;
  //   } catch (error) {
  //     // console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countGroPromise = fetchCountGro();

  // countGroPromise.then((countData) => {
  //   setCountGro(countData);
  // });

  // const fetchCountComp = async () => {
  //   try {
  //     const count = await getTestOfFalseDocuments("complian");
  //     return count;
  //   } catch (error) {
  //     // console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countCompPromise = fetchCountComp();

  // countCompPromise.then((countData) => {
  //   setCountComp(countData);
  // });

  // const fetchCountNH = async () => {
  //   try {
  //     const count = await getTestOfNewDocuments("not_Home");
  //     return count;
  //   } catch (error) {
  //     // console.error("Error getting false documents:", error);
  //     return 0;
  //   }
  // };

  // const countNHPromise = fetchCountNH();

  // countNHPromise.then((countData) => {
  //   setCountNH(countData);
  // });

  // useEffect(() => {}, [countSec, countDoc, countGro, countComp, countNH]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // Change the condition to compare with currentUser.SubRole
    if (
      currentUser &&
      (currentUser.SubRole === "button-one" ||
        currentUser.SubRole === "button-two" ||
        currentUser.SubRole === "button-three")
    ) {
      setShowModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className={classes.root}>
      {showModal && (
        <Modal
          style={{ marginTop: "100px" }}
          show={showModal}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Re-directing</Modal.Title>
          </Modal.Header>
          <Modal.Body>System is re-directing</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Continue
            </Button>
            {/* Additional modal footer content if needed */}
          </Modal.Footer>
        </Modal>
      )}
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{
          backgroundColor: "white",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.title} style={{ marginTop: "10px" }}>
            <i className="fa fa-clock-o" aria-hidden="true"></i>
            <Typography
              ref={appbarRef}
              component="p"
              className="emp-tag date"
              variant="p"
            >
              {Date().toLocaleString().substring(0, 24)}
            </Typography>
            <Typography
              component="p"
              variant="h6"
              style={{
                fontWeight: "bold",
                fontSize: isSmallScreen ? "16px" : "26px", // Adjust font size
                fontFamily: "Inter",
                fontWeight: "600",
                lineHeight: "34px",
                letterSpacing: "0em",
                textAlign: "left",
                marginTop: isSmallScreen ? "40px" : "40px",
                marginBottom: "40px", // Adjust margin top
                marginLeft: isSmallScreen ? "-140px" : "-200px", // Adjust margin left
              }}
            >
              Hi {JSON.parse(localStorage.getItem("currentUser")).name},
              Dashboard!
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        style={{
          borderRadius: "20px",
          borderTopLeftRadius: "0",
          borderBottomRightRadius: "20px",
          // marginBottom: '40px',
        }}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            {/* <ChevronLeftIcon /> */}
          </IconButton>
        </div>
        {open ? (
          // Top side of sidebar where we are greeting the user
          <React.Fragment>
            <div className="d-flex">
              <div
                style={{
                  background: "black",
                  width: "50px",
                  height: "50px",
                  marginLeft: "60px",
                  marginTop: "-30px",
                  marginBottom: "60px",
                }}
              >
                <img src={logoId} width="100px" height="100px" alt="logo" />
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {/* This contain the list of menu items like doctor etc */}
        <div className="list-wrap">
          {open ? (
            <List className="mt-1 side-list">
              {currentUser === "button-three" && (
                <React.Fragment>
                  <Link to="/">
                    <ListItem className="item-list">
                      <Icon
                        icon="ic:outline-local-grocery-store"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Grocery</h6>
                    </ListItem>
                  </Link>
                  <Link
                    to="/Orders"
                    onClick={() => {
                      // handleTrueDocs("grocery");
                    }}
                  >
                    <ListItem className="item-list">
                      <Icon
                        icon="solar:cart-5-linear"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <IconButton
                      // aria-label={countGro}
                      >
                        <Badge
                          // badgeContent={countGro} 
                          color="primary">
                          <h6 style={{ marginLeft: "-8px" }}>Orders </h6>
                        </Badge>
                      </IconButton>
                    </ListItem>
                  </Link>
                  <Link to="/CompletedGrocery">
                    <ListItem className="item-list">
                      <Icon
                        icon={clipboardTickOutlineIcon}
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Completed</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "button-one" && (
                <React.Fragment>
                  <Link to="/Security">
                    <ListItem className="item-list">
                      <Icon
                        icon="ic:round-security"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Security</h6>
                    </ListItem>
                  </Link>

                  <Link
                    to="/ProcessSec"
                    onClick={() => {
                      // handleTrueDocs("button-one");
                    }}
                  >
                    <ListItem className="item-list">
                      <Icon
                        icon="uil:process"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <IconButton
                      // aria-label={countSec}
                      >
                        <Badge
                          // badgeContent={countSec} 
                          color="primary">
                          <h6 style={{ marginLeft: "-8px" }}>Processing </h6>
                        </Badge>
                      </IconButton>
                    </ListItem>
                  </Link>
                  <Link to="/CompletedSecurity">
                    <ListItem className="item-list">
                      <Icon
                        icon="icon-park-outline:done-all"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Completed</h6>
                    </ListItem>
                  </Link>
                  <Link
                    to="/NotHome"
                    onClick={() => {
                      // handleOldDocuments("not_Home");
                    }}
                  >
                    <ListItem className="item-list">
                      <Icon
                        icon="tabler:smart-home-off"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <IconButton
                      // aria-label={countNH}
                      >
                        <Badge
                          // badgeContent={countNH}
                          color="primary">
                          <h6 style={{ marginLeft: "-8px" }}>Not at Home </h6>
                        </Badge>
                      </IconButton>
                    </ListItem>
                  </Link>
                  <Link to="/NotHomeCancelled">
                    <ListItem className="item-list">
                      <Icon
                        icon="clarity:cancel-line"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Cancelled </h6>
                    </ListItem>
                  </Link>
                  <Link to="/NotHomeCompleted">
                    <ListItem className="item-list">
                      <Icon
                        icon="teenyicons:calendar-tick-outline"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Arrived </h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "logo" && (
                <React.Fragment>
                  <Link to="/Logo">
                    <ListItem className="item-list">
                      <Icon
                        icon="ph:framer-logo-bold"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Logo</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "notification" && (
                <React.Fragment>
                  <Link to="/NotificationPanel">
                    <ListItem className="item-list">
                      <Icon
                        icon="material-symbols:notifications"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Notification</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "status" && (
                <React.Fragment>
                  <Link to="/Status">
                    <ListItem className="item-list">
                      <Icon
                        icon="pajamas:status-alert"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Status</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "complian" && (
                <React.Fragment>
                  <Link to="/Complaints">
                    <ListItem className="item-list">
                      <Icon
                        icon={clipboardIcon}
                        style={{ fontSize: "25px", color: "#7B7B7B" }}
                      />
                      <h6>Complaints</h6>
                    </ListItem>
                  </Link>
                  <Link
                    to="/Pending"
                    onClick={() => {
                      // handleTrueDocs("complian");
                    }}
                  >
                    <ListItem className="item-list">
                      <Icon
                        icon={pendingActionsIcon}
                        style={{ fontSize: "25px", color: "#7B7B7B" }}
                      />

                      <IconButton
                      // aria-label={countComp}
                      >
                        <Badge
                          // badgeContent={countComp} 
                          color="primary">
                          <h6 style={{ marginLeft: "-8px" }}>Processing </h6>
                        </Badge>
                      </IconButton>
                    </ListItem>
                  </Link>
                  <Link to="/Completed">
                    <ListItem className="item-list">
                      <Icon
                        icon={clipboardTickOutlineIcon}
                        style={{ fontSize: "25px", color: "#7B7B7B" }}
                      />
                      <h6>Completed</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "discounts" && (
                <React.Fragment>
                  <Link to="/Discounts">
                    <ListItem className="item-list">
                      <Icon
                        icon="ic:outline-discount"
                        style={{ fontSize: "25px", color: "#7B7B7B" }}
                      />
                      <h6>Discounts</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "button-two" && (
                <React.Fragment>
                  <Link to="/Doctor">
                    <ListItem className="item-list">
                      <Icon
                        icon="healthicons:doctor-outline"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Doctor</h6>
                    </ListItem>
                  </Link>
                  <Link
                    to="/Prescription"
                    onClick={() => {
                      // handleTrueDocs("consultation");
                    }}
                  >
                    <ListItem className="item-list">
                      <Icon
                        icon="fluent:doctor-48-regular"
                        color="#7b7b7b"
                        fontSize="25px"
                      />

                      <IconButton
                      //  aria-label={countDoc}
                      >
                        <Badge
                          // badgeContent={countDoc} 
                          color="primary">
                          <h6 style={{ marginLeft: "-8px" }}>Prescription </h6>
                        </Badge>
                      </IconButton>
                    </ListItem>
                  </Link>
                  <Link to="/CompletedDoctor">
                    <ListItem className="item-list">
                      <Icon
                        icon={clipboardTickOutlineIcon}
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Completed</h6>
                    </ListItem>
                  </Link>
                  {/* <Link to="/MailModal">
            <ListItem className="item-list">
              <Icon icon="fluent:doctor-48-regular" color="#7b7b7b" fontSize="25px" />
              <h6>MailModal</h6>
            </ListItem>
          </Link> */}
                </React.Fragment>
              )}
              {currentUser === "button-five" && (
                <React.Fragment>
                  <Link to="/VideoPanel">
                    <ListItem className="item-list">
                      <Icon
                        icon="material-symbols:live-tv-outline"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Video Panel</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "cms" && (
                <React.Fragment>
                  <Link to="/CMS">
                    <ListItem className="item-list">
                      <Icon
                        icon="solar:gallery-add-bold"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6 style={{ fontSize: "20px" }}>Feed</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}


              {currentUser === "annoucements" && (
                <React.Fragment>
                  <Link to="/Annoucements">
                    <ListItem className="item-list">
                      <Icon
                        icon="solar:gallery-add-bold"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6 style={{ fontSize: "20px" }}>Annoucements</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}

              {currentUser === "residence" && (
                <React.Fragment>
                  <Link to="/Residence">
                    <ListItem className="item-list">
                      <Icon
                        icon="tdesign:member"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Residents</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "billing" && (
                <React.Fragment>
                  <Link to="/Billing">
                    <ListItem className="item-list">
                      <Icon
                        icon="ri:bill-line"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Billing</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "plots" && (
                <React.Fragment>
                  <Link to="/Plots">
                    <ListItem className="item-list">
                      <Icon
                        icon="mdi:land-plots-marker"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Plots</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "cms-all" && (
                <React.Fragment>
                  <Link to="/CMS">
                    <ListItem className="item-list">
                      <Icon
                        icon="solar:gallery-add-bold"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6 style={{ fontSize: "20px" }}>Feed</h6>
                    </ListItem>
                  </Link>
                  <Link to="/Plots">
                    <ListItem className="item-list">
                      <Icon
                        icon="mdi:land-plots-marker"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Plots</h6>
                    </ListItem>
                  </Link>
                  <Link to="/Discounts">
                    <ListItem className="item-list">
                      <Icon
                        icon="ic:outline-discount"
                        style={{ fontSize: "25px", color: "#7B7B7B" }}
                      />
                      <h6>Discounts</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              {currentUser === "super-admin" && (
                <React.Fragment>
                  <Link to="/NotificationPanel">
                    <ListItem className="item-list">
                      <Icon
                        icon="material-symbols:notifications"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Notification</h6>
                    </ListItem>
                  </Link>
                  <Link to="/Billing">
                    <ListItem className="item-list">
                      <Icon
                        icon="ri:bill-line"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Billing</h6>
                    </ListItem>
                  </Link>
                  <Link to="/Residence">
                    <ListItem className="item-list">
                      <Icon
                        icon="tdesign:member"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Residents</h6>
                    </ListItem>
                  </Link>
                  <Link to="/VideoPanel">
                    <ListItem className="item-list">
                      <Icon
                        icon="material-symbols:live-tv-outline"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Video Panel</h6>
                    </ListItem>
                  </Link>
                  <Link to="/Status">
                    <ListItem className="item-list">
                      <Icon
                        icon="pajamas:status-alert"
                        color="#7b7b7b"
                        fontSize="25px"
                      />
                      <h6>Status</h6>
                    </ListItem>
                  </Link>
                </React.Fragment>
              )}
              <Link>
                <ListItem
                  className="item-list"
                  onClick={(e) => {
                    localStorage.clear();
                    window.location.href = "Login";
                  }}
                >
                  <Icon
                    icon={logoutOutlineIcon}
                    style={{ fontSize: "25px", color: "#7b7b7b" }}
                  />
                  <h6>Log Out</h6>
                </ListItem>
              </Link>
            </List>
          ) : (
            mainListItemsClose
          )}
        </div>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div style={{ textAlign: "center" }}>
            <Button
              ref={btn}
              onClick={toggle}
              variant="contained"
              color="success"
              style={{ display: "none" }}
            >
              {playing ? "Pause" : "Play"}
            </Button>
          </div>
          <Paper className={classes.paper}>{children}</Paper>
        </Container>
      </main>
    </div>
  );
}
